import Head from 'next/head';
import { productTourContactOgData } from './og-data';

type Props = {
  lang: string;
};

export const DemoMetadata = ({ lang }: Props) => {
  const langOgData = productTourContactOgData[lang] || productTourContactOgData['en'];
  const pageTitle = `${langOgData.ogTitle} | Myenwsdesk`;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
      <meta name="description" content={langOgData.ogDescription} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={langOgData.ogTitle} />
      <meta property="og:url" content={langOgData.ogUrl} />
      <meta property="og:description" content={langOgData.ogDescription} />
      <meta property="og:site_name" content="Mynewsdesk" />
      <meta name="robots" content="noindex" />
    </Head>
  );
};
